import React from "react"
import { graphql } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./AboutUs.module.css"
import altStyles from "../templates/WhatWeDo.module.css"
import HubspotForm from "components/HubspotForm"
import Blocks from "components/Blocks"

// eslint-disable-next-line
export const AboutUsPage = ({ data, blogPosts, ...props }) => {

  return (
    <Layout {...props} {...data}>
      <div className={styles.preview}>
        <Container className={styles.previewcontainer}>
          <div>
            <Title className={altStyles.bannertitle}>{data.title}</Title>
            <p className={`${altStyles.title} ${altStyles.titlesmall} ${styles.title}`}>
              <span dangerouslySetInnerHTML={{ __html: data.bannerTitle || "" }} />
            </p>
            <div dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
          </div>
          {data.image ? (
            <div className={altStyles.image}>
              {data.image.extension === "svg" ? (
                <img alt={data.name} title={data.name} src={data.image.publicURL} loading="lazy" width="585" height="486"/>
              ) : data.image.childImageSharp ? (
                <Img
                  loading="lazy"
                  objectFit="contain"
                  alt={data.name}
                  title={data.name}
                  fluid={data.image.childImageSharp.fluid}
                />
              ) : null}
            </div>
          ) : null}
        </Container>
      </div>

      {(data.blocks || []).map((block, i) => {
      const Elem = Blocks[block.type]
      return Elem ? <Elem key={i} 
      {...block} 
      {...blogPosts}
      /> : null
    })}

      {data.form && data.portalId ? (
      <div className={altStyles.grayBackground} id="contact-form">
        <Container>
        <div className={altStyles.titleContainer}>
            <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span><span dangerouslySetInnerHTML={{ __html: data.formTitle || ""}} /></TitleBlock>
          </div>
          <HubspotForm id={data.form} portalId={data.portalId} />
        </Container>
      </div>
    ) : null}
    </Layout>
  )
}

// eslint-disable-next-line
export const AboutUsPageWrapper = ({ data, ...props }) => (
  <AboutUsPage
    {...props}
    headerImage={data.headerImage}
    pinIcon={data.pinIcon}
    letterIcon={data.letterIcon}
    data={data.markdownRemark.frontmatter}
		blogPosts={data.blogPosts}

  />
)

export default AboutUsPageWrapper

export const pageQuery = graphql`
  query AboutUsQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us" } }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        bannerTitle
        description
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        form
        portalId
        formTitle
				blocks {
          type
          title
          secondTitle
          thirdTitle
          description
          buttonText
          buttonUrl
          buttonPosition
          subTitle
          layout
          bgColor
          imagePosition
          imageWidth
          paddingTop
					paddingBottom
					iconSize
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          secondImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          blobIcons {
            title
            link
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          iconsGridList {
            title
            subtitle
            description
            link
            imageAltText
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          chessGridList {
            title
            description
            link
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imagesGrid {
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          fullWidthCardsList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          cards {
            title
            text
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          gridWithIconsAndImageList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          iconsPathList {
            title
            description
            icon {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          partners {
            imageAltText
            link
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          textWithImage {
            title
            text
          }
          textWithCards {
            title
            preTitle
            description
            text
            buttonText
            buttonUrl
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 100, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
		blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { templateKey: { eq: "blog" } },
        isFuture: { eq: false }
      }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            tags
            description
            date(formatString: "MMM Do, YYYY")
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 338) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
